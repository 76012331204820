import { Logger } from "tslog";

import { logLevelFromName } from "./logging.utils";

const logger = new Logger({
  type: "pretty",
  name: "athletify",
  minLevel: logLevelFromName("info"),
});

export default logger;
