export function logLevelFromName(name: string): number {
  switch (name) {
    case "silly":
      return 0;
    case "trace":
      return 1;
    case "debug":
      return 2;
    case "info":
      return 3;
    case "warn":
    case "warning":
      return 4;
    case "error":
      return 5;
    case "fatal":
      return 6;
    default:
      return 3;
  }
}
